<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br /><br />

      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->
          <br />
        </div>

        <br />
        <br />
        <h3>PERFIL CERVICAL</h3>
        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>

          {{ elemento }}
          <p></p>
          <p></p>
          <p></p>
          <p></p>
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong>Dra {{ medico.nome == 'Adriana Melo (curso)'? 'Adriana Melo': medico.nome }}</strong> </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';
export default {
  mixins: [ImprecoesMixins],
  mounted() {
    
    if(this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];

    

    if (this.laudo.coloUterino)
      folha.push(
        `Colo uterino ${this.laudo.coloUterino}, medindo ${this.laudo.coloMedidaEmRepouco} mm (colo avaliado por três minutos).`
      );

    if (this.laudo.coloPassouAMedir)
      folha.push(
        `Após três minutos de avaliação, o colo ${this.laudo.coloPassouAMedir} ${this.laudo.cmdois} mm.`
      );

    if (
      this.laudo.orificioCervicalInterno &&
      this.laudo.orificioCervicalInterno == 'aberto'
    )
      folha.push(
        ` Orifício cervical interno ${this.laudo.orificioCervicalInterno} com ${this.laudo.orificioCervicalInterno2} mm.`
      );
      if (
      this.laudo.orificioCervicalInterno &&
      this.laudo.orificioCervicalInterno != 'aberto'
    )
      folha.push(
        ` Orifício cervical interno ${this.laudo.orificioCervicalInterno}.`
      );

    if (this.laudo.protusaoBolsaAmniotica)
      folha.push(
        `${this.laudo.protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.`
      );
    if (this.laudo.sinalDeSludge)
      folha.push(`Sinal de sludge ${this.laudo.sinalDeSludge}.`);

    if (
      this.laudo.polipoEndocervicalMedindo &&
      this.laudo.polipoEndocervicalMedindo ==
        'Observamos pólipo endocervical medindo'
    )
      folha.push(
        `${this.laudo.polipoEndocervicalMedindo} ${this.laudo.polipoEndocervicalMedindoCm} mm.`
      );
    if (folha.length > 0) folha.splice(0, 0, '');
    this.coloUterinoz = [...folha];
    folha = [];

    if (this.laudo.coloUterino2)
      folha.push(`Colo uterino ${this.laudo.coloUterino2}.`);

    if (this.laudo.comentarioAdcionalPerfilCervical)
      folha.push(`${this.laudo.comentarioAdcionalPerfilCervical}.`);
    if (folha.length > 0) folha.splice(0, 0, 'Conclusão');
    this.conclusao = [...folha];
    folha = [];

    const todos = [...this.coloUterinoz, ...this.conclusao];

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, 30));
    }
  },

  data() {
    return {
      coloUterinoz: [],
      conclusao: [],
      via : '',
      tamanhoElemento: [],
      titulos: ['Colo Uterino', 'Conclusão'],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: -5px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  div {
    margin-left: 0px;
  }

  h4 {
    text-align: justify;
    margin-left: 0px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: 0px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
  footer {
    // position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
